document.addEventListener('DOMContentLoaded', function () {
    const popup = document.querySelector('#js-popup');
    const overlay = document.querySelector('#js-popupOverlay');
    const closeButtons = document.querySelectorAll('.js-popupCloseButton');
console.log(sessionStorage.getItem('popupClosed'))
    if (!sessionStorage.getItem('popupClosed')) {
        console.log('here')
        overlay.classList.remove('hidden');
        popup.classList.remove('hidden');
    }
    closeButtons.forEach(button => {
        button.addEventListener('click', function () {
            overlay.classList.add('hidden');
            popup.classList.add('hidden');

            sessionStorage.setItem('popupClosed', 'true');
        });
    });
}, false);
